<template>
  <div>
    <h1>工程综合助手</h1>
    <div class="list">
<!--      <div class="item">-->
<!--      <i class="chame chame-xunjianguanli" />-->
<!--      <span>巡检管理</span>-->
<!--      </div>-->
      <div class="item" @click="handleItem(1)">
        <i class="chame chame-baoxiu" />
        <span>公区报修</span>
      </div>
      <div class="item" @click="handleItem(2)">
        <i class="chame chame-repair" />
        <span>维修管理</span>
      </div>
      <div v-if="user_info.is_schedul" class="item" @click="handleItem(3)">
        <i class="chame chame-jiaoseguanli" />
        <span>排班管理</span>
      </div>
      <div class="item" @click="handleItem(4)">
        <i class="chame chame-zhengzhaoliebiao" />
        <span>证照列表</span>
      </div>
      <div class="item item-red" @click="handleItem(5)">
        <i class="chame chame-genggaimima" />
        <span>更改密码</span>
      </div>

      <!-- 退出登录 -->
      <div class="logout-box">
        你好！ {{user_info.nickname}}
        <div class="logout-btn" @click="logout">
          <i class="chame chame-Logout"></i>
          退出
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepairHelper',
  data() {
    return {
      user_info: {}, // 用户信息
    }
  },
  created() {
    if (!window.localStorage.getItem('user_info')) return
    this.user_info = JSON.parse(window.localStorage.getItem('user_info'))
  },
  methods: {
    handleItem(index) {
      if (index === 1) {
        // 公区报修
        this.$router.push('/repair')
      } else if (index === 2) {
        // 维修管理
        this.$router.push('/repair/list')
      } else if (index === 3) {
        // 排版管理
        this.$router.push('/repair/arrange')
      } else if (index === 4) {
        // 证照列表
        this.$router.push('/repair/license/list')
      } else if (index === 5) {
        // 更改密码
        this.$router.push('/repair/pwd')
      }
    },
    // 退出登录
    logout() {
      this.$dialog({
        title: '您确定要退出登录吗？',
        showCancelButton: true
      }).then(() => {
        window.localStorage.clear()
        this.$router.push('/login')
      }).catch(() => {})
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  padding: 100px 0;
  text-align: center;
}
.list {
  .item {
    margin: 40px 100px;
    background-color: #1989fa;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &:active {
      opacity: 0.9;
    }
    .chame {
      font-size: 100px;
      margin-right: 40px;
    }
    span {
      font-size: 36px;
    }
  }
  .item-red {
    background-color: #ea0024;
  }
}

.logout {
  padding: 100px 200px 0;
}
</style>
